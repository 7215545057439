@import '../../variables.scss';

.navBar {
  display: flex;
  flex-direction: column;
  height: auto;
  flex: 1;
  padding: 10px 0;

  // Override Blueprintjs
  .bp3-navbar {
    &-heading {
      .logo {
        width: 80px;
      }
    }
  }

  .header-button-label {
    display: none;
  }

  &__group {
    margin-top: 20px;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: inset 2px 1px 1px 0 rgba(0, 0, 0, 0.5);
    flex-direction: column;
    height: auto;
    margin: auto 0;
    margin-top: 0;

    &:last-of-type {
      margin-bottom: 0;
    }

    ul {
      list-style: none;
      text-align: center;
      width: 100%;
      margin: 0;
      padding: 0;
    }
    li {
      width: 100%;
      position: relative;
    }

    .header-link {
      width: 100%;
      margin: 10px;

      button {
        display: block;
        position: relative;
        margin: 0 auto;
        padding: 0.5em 1em;
        z-index: 1;
      }

      &.active {
        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: $solidgray;
        }
        &::after {
          content: '';
          position: absolute;
          right: 0;
          top: calc(50% - 5px);
          background-image: url('/images/nav__arrow-right.svg');
          background-repeat: no-repeat;
          display: inline-block;
          width: 15px;
          height: 20px;
          vertical-align: middle;
        }
      }
    }
  }
}

.modal-signin {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 130;

  &__form {
    max-width: 30rem;
    min-width: 20rem;

    &-buttons {
      display: flex;
      justify-content: center;

      button {
        margin: 0.25rem;
      }
    }
  }
}
